<template>
  <div class="mt-5">
    <lineFieldsContainer
      :fieldAttrRow="{}"
      :fields="fields"
      :valueLine="valueLine"
      @field-line-changed="checkEventValue(...arguments)"
      :templateContent="result"
    ></lineFieldsContainer>
  </div>
</template>
<script>
import lineFieldsContainer from "@/commonComponents/lineFieldsContainer.vue";
import options from "@/cfg/options.json";

export default {
  components: { lineFieldsContainer },
  props: ["result", "field", "fieldAttributes","value"],
  data() {
    return {
      retry: [...Array(10).keys()],
      unit: ["bps", "kbps", "Mbps"],
      action: ["stop test", "stop step", "warning", "ignore"],
    };
  },
  computed: {
    valueLine() {
      let value = this.value;
      if (typeof value != "undefined" && typeof value === "string") {
        let givenValue = value;
        let storedRetry = "";
        let storedEnd = "";
        //console.log(givenValue);
        var storedArray = givenValue.split(";");
        if (Array.isArray(storedArray)) {
          if (typeof storedArray[1] != "undefined") {
            storedRetry = storedArray[1];
          }
          if (typeof storedArray[2] != "undefined") {
            storedEnd = storedArray[2];
          }
        }
        let finalVal = { retry: storedRetry, end: storedEnd };
        return finalVal;
      } else {
        let line = { retry: "0", end: "stop test" };
        if (this.fieldAttributes.for == "gsmdata") {
          line = { retry: "0", end: "abort" };
        }
        this.checkEventValue(line);
        return line;
      }
    },
    fields() {
      let fields = {
        str1: {
          type: "string",
          string: "On Error:",
          fieldAttrInput: {
            class: " ",
          },
          colAttrs: {
              style: "max-width: 65px;",
          },
        },
        retry: {
          type: "select",
          associatedOption: this.retry,
          name: "retry",
        },
        str2: {
          type: "string",
          string: "times, then",
          fieldAttrInput: {
            class: "mt-3",
          },
          colAttrs: {
              style: "max-width: 75px;",
          },
        },
        end: {
          type: "select",
          associatedOption: this.getOptions,
          name: "action",
        },
      };
      return fields;
    },
  },
  methods: {
    getOptions() {
      if (typeof this.fieldAttributes.for != "undefined") {
        if (this.fieldAttributes.for == "gsmdata") {
          return options.gcAction;
        } else {
          //file download is different option
          return this.action;
        }
      } else {
        return this.action;
      }
    },

    checkEventValue(line) {
      let result = "";
      if (
        typeof line["retry"] != "undefined" &&
        typeof line["end"] != "undefined"
      ) {
        result = "retry;" + line["retry"] + ";" + line["end"];
      }
      this.$emit("input", result);
    },
  },
};
</script>